import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {Col, Row, Container, Tabs, Tab} from "react-bootstrap";
import Img from "gatsby-image";
import meetTheDoulasStyle from "./meet-the-doulas.module.css";
import globalStyle from "../styles/global.module.css";


const DoulaBioPage = ({data}) => {

    return (
      <Layout className="site-content">
        <Container>

          <div className="title">
            <h1 className={globalStyle.entryTitle}>Meet the Doulas</h1>
          </div>

          <Tabs defaultActiveKey="centralSanDiego" transition={false} id="regionTabs" className={meetTheDoulasStyle.navTabs}>
            <Tab className={meetTheDoulasStyle.tabContent} eventKey="centralSanDiego" title="Central San Diego">
              <Row>
                <Col md={4} sm={4} xs={12}>
                  <h3 className={meetTheDoulasStyle.doulaName}>Kelly Dalrymple</h3>
                  <Img fluid={data.kellyDalrymplePhoto.childImageSharp.fluid}></Img>
                  <div className={meetTheDoulasStyle.contactDetails}>
                    <p>Contact Info:</p>
                    <p><i className={[meetTheDoulasStyle.meetTheDoulasIcons, "fas fa-globe"].join(" ")}></i> <a href="https://www.kellyjeanneyoga.com/nuumama" rel="noopener noreferrer" target="_blank">www.kellyjeanneyoga.com</a></p>
                    <p><i className={[meetTheDoulasStyle.meetTheDoulasIcons, "fas fa-phone"].join(" ")}></i> <a href="tel:858-414-3049">858-414-3049</a></p>
                    <p><i className={[meetTheDoulasStyle.meetTheDoulasIcons, "fas fa-envelope"].join(" ")}></i> <a href="mailto:kellyjdalrymple@gmail.com">kellyjdalrymple@gmail.com</a></p>
                  </div>
                </Col>
                <Col md={8} sm={8} xs={12}>
                  <Tabs defaultActiveKey="support" id="detailTabs">
                    <Tab eventKey="support" title="Support Services" className={meetTheDoulasStyle.detailsTabs}>
                      <ul>
                        <li>2 Prenatal Home Visits.</li>
                        <li>Unlimited Email/Phone/Text support providing holistic pregnancy counseling and resources -
                          through pregnancy until 6 weeks postpartum.</li>
                        <li>24/7 on call starting at 38 weeks.</li>
                        <li>Help creating birth plan/preferences.</li>
                        <li>Continuous in person support throughout your birth in home, hospital or birth center.</li>
                        <li>Unlimited access to my on demand prenatal yoga series as well as one private, in person yoga
                          session per trimester - to feel more connected to your pregnancy and more prepared for birth.</li>
                        <li>Present 1-2 hours after birth to assist with breastfeeding.</li>
                        <li>One postpartum visit (at your request).</li>
                        <li>Email/Phone/Text support for 6 weeks postpartum.</li>
                      </ul>
                    </Tab>
                    <Tab eventKey="specialties" title="Specialties and Certifications" className={meetTheDoulasStyle.detailsTabs}>
                      <ul>
                        <li>Birth Doula training at Bini Birth in Los Angeles.</li>
                        <li>Currently working on CLEC (Certified Lactation Educator Course) through UCSD</li>
                      </ul>
                    </Tab>
                    <Tab eventKey="about" title="About" className={meetTheDoulasStyle.detailsTabs}>
                      <p className={meetTheDoulasStyle.doulaBio}>
                        My winding path to becoming a Doula and prenatal yoga instructor started in college where I studied Kinesiology
                        and worked as a personal trainer. After college I continued my education of the body at Equinox Fitness Academy.
                        A geeky fascination with the physical body led me to grow more curious about the mind and the spirit,
                        inspiring me to travel to Bali to complete my Yoga Teacher Training with Zuna Yoga. Curious and inspired,
                        I continued to expand my understanding of the human condition, studying under Oprah’s very own spiritual mentor
                        and life coach, Martha Beck, to become an intuitive life + wellness coach. On the pursuit of pulling all of
                        the pieces of my trainings together, the universe planted a little seed of realization that childbirth is the
                        ultimate expression of the human body, mind and spirit; with this realization, it felt like everything
                        clicked into place. A spark went off and a passionate fire was kindled.

                        The Doula really is the missing link in the birthing experience - an adjunct to the medical model that can
                        fill in all the gaps by educating and guiding new mothers through all aspects of the vulnerable, uncertain,
                        and beautiful transition into motherhood. As that missing link, I am here for you to assist with conscious birth
                        preparation and provide holistic support throughout.
                      </p>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Tab>

            <Tab className={meetTheDoulasStyle.tabContent} eventKey="temecula" title="Temecula">
              <Row>
                <Col md={4} sm={4} xs={12}>
                  <h3 className={meetTheDoulasStyle.doulaName}>Nicole Burkett</h3>
                  <Img fluid={data.nicoleBurkettPhoto.childImageSharp.fluid}></Img>
                  <div className={meetTheDoulasStyle.contactDetails}>
                    <p>Contact Info:</p>
                    <p><i className={[meetTheDoulasStyle.meetTheDoulasIcons, "fas fa-phone"].join(" ")}></i> <a href="tel:760-500-4876">760-500-4876</a></p>
                    <p><i className={[meetTheDoulasStyle.meetTheDoulasIcons, "fas fa-envelope"].join(" ")}></i> <a href="mailto:nicole@ameetingspace.com">nicole@ameetingspace.com</a></p>
                  </div>
                </Col>
                <Col md={8} sm={8} xs={12}>
                  <Tabs defaultActiveKey="support" id="detailTabs">
                    <Tab eventKey="support" title="Support Services" className={meetTheDoulasStyle.detailsTabs}>
                      <h4>Birth/labor package includes:</h4>
                      <ul>
                        <li>2 scheduled meetings during pregnancy.</li>
                        <li>Client contract.</li>
                        <li>Unlimited calls, texts, emails, and virtual support to answer any questions, provide research,
                          provide a listening unbiased ear.</li>
                        <li>On-call starting at 37 weeks.</li>
                        <li>Phone, text, virtual, in-person support (if permitted in hospital or birth center) during birth.</li>
                        <li>In-person support from moment you call up to 90 minutes after birth.</li>
                        <li>Breastfeeding support.</li>
                        <li>2 postpartum visits.</li>
                      </ul>

                      <h4>Postpartum packages:</h4>
                      <div className={meetTheDoulasStyle.postpartumPackages}>
                        <h5>Virtual package</h5>
                        <ul>
                          <li>30 minute or 60 minute call to answer any questions.</li>
                          <li>Email all questions at least 24 hours ahead of scheduled time so I can collect all data and
                            research so our time is used well together.
                          </li>
                        </ul>

                        <h5>In person package</h5>
                        <ul>
                          <li>In-home.</li>
                          <li>Observe and provide help recognizing hunger cues, feedings and pace-feeding (if bottle feeding).</li>
                          <li>Observe mothers well-being.</li>
                          <li>Sit with child so parents can nap, shower, cook, etc.</li>
                          <li>Light housekeeping, cooking.</li>
                        </ul>
                      </div>
                    </Tab>
                    <Tab eventKey="specialties" title="Specialties and Certifications" className={meetTheDoulasStyle.detailsTabs}>
                      <ul>
                        <li>Bebo Mia Maternal Support Person (MSP) Spring 2018</li>
                      </ul>
                    </Tab>
                    <Tab eventKey="about" title="About" className={meetTheDoulasStyle.detailsTabs}>
                      <p className={meetTheDoulasStyle.doulaBio}>
                        After witnessing my first birth at the age of 18, I have always been fascinated by the raw power of a laboring body.
                        However, life is not linear and hardly ever goes as you plan.
                        The following year I had my first baby and struggled with being 19 and raising a baby.
                        I was lost, had no clue how to make the important, proper decisions while doing it mostly on my own.
                        My parents were a wonderful support system, helping me to navigate the research. I never took for granted
                        the support and help that I had, I know many do not have that support.
                        5 years later I studied massage therapy and fell in love with prenatal massage.
                        How beautiful a gift to be able to help ease the ever changing pregnant body.
                        Many years later, I found the way to tie these two passions by becoming a doula and receiving a partial
                        scholarship to Bebo Mia gave me the push I needed.
                        My mission is to help guide families from conception to postpartum and breastfeeding with a nonbiased,
                        research backed approach.
                      </p>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </Layout>
    )
};

export const query = graphql`
  query {
    vimeoVideos: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "doula"}}}},
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
    kellyDalrymplePhoto: file(relativePath: { eq: "doula/kelly-dalrymple.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    } 
    nicoleBurkettPhoto: file(relativePath: { eq: "doula/nicole-burkett.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    } 
  }
`;

export default DoulaBioPage
